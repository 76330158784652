import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "recommended-categories",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      recommendedCategoryId: null,
      valid: false,
      recommendedCategory: {
        startDate: null,
        endDate: null,
        cityId: null,
        categoryId: null
      },
      categories: [],
      cities: [],
      search: "",
      categoriesIsLoading: false,
      citiesIsLoading: false,
      startDateMenu: false,
      endDateMenu: false
    };
  },
  created() {
    this.recommendedCategoryId = this.$route.params.recommendedCategoryId;
    let title =
      this.recommendedCategoryId != null
        ? "Category details"
        : "Insert new category";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.recommendedCategoryId != null) {
      ApiService.get(
        `api/recommended-categories`,
        `${this.recommendedCategoryId}`
      )
        .then(response => {
          this.$log.debug("Recommended Category: ", response.data);
          this.recommendedCategory = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCategories();
    this.searchCities();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    searchCategories() {
      // Items have already been loaded
      if (this.categories.length > 0) return;

      // Items have already been requested
      if (this.categoriesIsLoading) return;

      this.categoriesIsLoading = true;
      ApiService.query("api/shop-categories")
        .then(res => {
          this.categories = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.categoriesIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveRecommendedCategory(this.recommendedCategory);
      }
    },
    saveRecommendedCategory(recommendedCategory) {
      this.loading = true;
      if (recommendedCategory.id != null) {
        ApiService.put(`api/recommended-categories`, recommendedCategory)
          .then(response => {
            this.$log.debug("Recommended Category changed: " + response);
            this.$emit("recommendedCategorySaved", "recommendedCategorySaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.previousState();
          });
      } else {
        ApiService.post(`api/recommended-categories`, recommendedCategory)
          .then(response => {
            this.$log.debug("Recommended Category created: " + response);
            this.$emit("recommendedCategorySaved", "recommendedCategorySaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
